import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiUtils from "../../api/ApiUtils";
import Toaster from "../../helper/Toaster";
import { ERROR_TOASTIFY_TYPE, SUCCESS_TOASTIFY_TYPE } from "../../helper/enum";
import DatePicker from "react-datepicker";
import { debounce } from "../../helper/Debounce";
import moment from "moment";
import { ADMIN, HR, MANAGER, ONE, TEN } from "../../config/constants";

const LeaveRequests = () => {
  const [reason, setReason] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [leaveId, setLeaveId] = useState();
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(ONE);
  const [totalCount, setTotalCount] = useState();
  const [totalRecords, setTotalRecords] = useState(ONE);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const formatedStartDate =
    startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "";
  const formatedEndDate =
    endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "";

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const id = useSelector((state) => state?.authSlice?.userDetails);

  const handleChange = (e) => {
    setReason(e.target.value);
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handleSubmit = () => {
    const apiData = {
      userLeaveId: leaveId.userLeaveId,
      leaveStatus: 3,
      remark: reason,
      role: id.userRole,
    };
    ApiUtils.userLeaveApproveOrReject(apiData).then((res) => {
      if (res.data.isSuccess) {
        Toaster(res.data.message, SUCCESS_TOASTIFY_TYPE);
        setShowPopup(false);
        setReason("");
        getLeaveListByHr();
      }
      if (res.data.isSuccess) {
      } else {
        Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
      }
    });
  };

  const handleApprove = (data) => {
    const apiData = {
      userLeaveId: data.userLeaveId,
      leaveStatus: 2,
      role: id.userRole,
    };
    ApiUtils.userLeaveApproveOrReject(apiData).then((res) => {
      if (res.data.isSuccess) {
        Toaster(res.data.message, SUCCESS_TOASTIFY_TYPE);
        getLeaveListByHr();
      }
      if (res.data.isSuccess) {
      } else {
        Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
      }
    });
  };

  async function getLeaveListByHr() {
    await ApiUtils.getLeavesByHr(
      `${id.userId}?Page=${currentPage}&PageSize=${TEN}&SearchValue=${searchValue}&fromDate=${formatedStartDate}&toDate=${formatedEndDate}`
    )
      .then((res) => {
        setList(res.data.data.listResponse ? res.data.data.listResponse : []);
        setTotalCount(
          res?.data?.data?.totalCount ? res?.data?.data?.totalCount : ""
        );
        setTotalRecords(
          res?.data?.data?.totalCount
            ? Math.ceil(res?.data?.data?.totalCount / TEN)
            : []
        );
      })
      .catch((err) => {
        return err;
      });
  }
  async function getLeaveListByAdmin() {
    await ApiUtils.getLeavesByAdmin(
      `Page=${currentPage}&PageSize=${TEN}&SearchValue=${searchValue}&fromDate=${formatedStartDate}&toDate=${formatedEndDate}`
    )
      .then((res) => {
        setList(res.data.data.listResponse ? res.data.data.listResponse : []);
        setTotalCount(
          res?.data?.data?.totalCount ? res?.data?.data?.totalCount : ""
        );
        setTotalRecords(
          res?.data?.data?.totalCount
            ? Math.ceil(res?.data?.data?.totalCount / 10)
            : []
        );
      })
      .catch((err) => {
        return err;
      });
  }

  useEffect(() => {
    if (id.userRole === ADMIN) {
      getLeaveListByAdmin();
    } else {
      getLeaveListByHr();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, startDate, endDate]);

  const handleSearch = async (value) => {
    if ((list.length > 0 && value.length>=3) || value.length===0) {
      setSearchValue(value);
      await ApiUtils.getLeavesByHr(
        `${id.userId}?Page=${currentPage}&PageSize=${TEN}&SearchValue=${value}&fromDate=${formatedStartDate}&toDate=${formatedEndDate}`
      )
        .then((res) => {
          setList(res.data.data.listResponse ? res.data.data.listResponse : []);
          setTotalCount(
            res?.data?.data?.totalCount ? res?.data?.data?.totalCount : ""
          );
          setTotalRecords(
            res?.data?.data?.totalCount
              ? Math.ceil(res?.data?.data?.totalCount / 10)
              : []
          );
        })
        .catch((err) => {
          return err;
        });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(handleSearch), [list]);

  const handleRejectClick = (id) => {
    setShowPopup(true);
    setLeaveId(id);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setReason("");
  };



  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const openModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <React.Fragment>
      <div className="flex items-center justify-end gap-4 mt-3 pb-2">
        <div className="container flex items-center justify-between">
          <h1 className="text-lg font-bold">LEAVE REQUESTS</h1>
        </div>
        <div>
          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
        </div>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            type="text"
            id="table-search"
            className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search for items"
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
        <div className="flex">
          <div className="flex">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              showIcon
              todayButton="TODAY"
              selected={startDate}
              onChange={handleStartDateChange}
              placeholderText="Select start date"
              className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="flex">
            <label className="ml-2 mr-2">To:</label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              showIcon
              todayButton="TODAY"
              selected={endDate}
              onChange={handleEndDateChange}
              placeholderText="Select end date"
              className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs uppercase  bg-gray-700 text-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Department
              </th>
              <th scope="col" className="px-6 py-3">
                Role
              </th>
              <th scope="col" className="px-6 py-3">
                Leave Type
              </th>
              <th scope="col" className="px-6 py-3">
                Start date / End date
              </th>
              <th scope="col" className="px-6 py-3">
                Total Days
              </th>
              <th scope="col" className="px-6 py-3">
                Reason for Leave
              </th>
              {id.userRole === MANAGER ? (
                <th scope="col" className="px-6 py-3">
                  Status by HR
                </th>
              ) : id.userRole === HR ? (
                <th scope="col" className="px-6 py-3">
                  Status by Manager
                </th>
              ) : (
                ""
              )}
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.length > 0 ? (
              list.map((data, index) => {
                const truncatedReason =
                  data.reasonForLeave.length <= 50
                    ? data.reasonForLeave
                    : data.reasonForLeave.slice(0, 50) + "...";
                return (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4">{data.userName}</td>
                    <td className="px-6 py-4">{data.department}</td>
                    <td className="px-6 py-4">{data.role}</td>
                    <td className="px-6 py-4">{data.leaveType}</td>
                    <td className="px-6 py-4">
                      {data.startDate} to {data.endDate}
                    </td>
                    <td className="px-6 py-4">{data.totalDays}</td>
                    <td className="px-6 py-4">
                      {truncatedReason}
                      {data.reasonForLeave.length > 50 && (
                        <button
                          onClick={() => openModal(data.reasonForLeave)}
                          className="ml-2 text-blue-500"
                        >
                          Show More
                        </button>
                      )}
                    </td>
                    {id.userRole === MANAGER ? (
                      <td className="px-6 py-4">
                        <span
                          className={
                            "inline-block py-1 px-3 rounded-full text-sm font-semibold " +
                            (data.statusByHR === 1
                              ? "text-yellow-300"
                              : data.statusByHR === 2
                              ? " text-green-300"
                              : data.statusByHR === 3
                              ? " text-red-500"
                              : data.statusByHR === 4
                              ? "text-gray-300"
                              : "")
                          }
                        >
                          {data.statusByHR === 1
                            ? "Pending"
                            : data.statusByHR === 2
                            ? "Approved"
                            : data.statusByHR === 3
                            ? "Rejected"
                            : data.statusByHR === 4
                            ? "Canceled"
                            : ""}
                        </span>
                      </td>
                    ) : id.userRole === HR ? (
                      <td className="px-6 py-4">
                        <span
                          className={
                            "inline-block py-1 px-3 rounded-full text-sm font-semibold " +
                            (data.statusByManager === 1
                              ? "text-yellow-300"
                              : data.statusByManager === 2
                              ? " text-green-300"
                              : data.statusByManager === 3
                              ? " text-red-500"
                              : data.statusByManager === 4
                              ? "text-gray-300"
                              : "")
                          }
                        >
                          {data.statusByManager === 1
                            ? "Pending"
                            : data.statusByManager === 2
                            ? "Approved"
                            : data.statusByManager === 3
                            ? "Rejected"
                            : data.statusByManager === 4
                            ? "Canceled"
                            : ""}
                        </span>
                      </td>
                    ) : (
                      ""
                    )}
                    <td className="px-6 py-4 flex gap-5">
                      {data.status !== 4 ? (
                        <>
                          <button
                            disabled={
                              (id.userRole === "HR" && data.statusByHR === 2) ||
                              (id.userRole === "Manager" &&
                                data.statusByManager === 2)
                                ? true
                                : false
                            }
                            onClick={() => handleApprove(data)}
                            className="bg-transparent disabled:cursor-no-drop disabled:opacity-25 hover:bg-green-500 text-green-700 font-semibold hover:text-white  px-2 border border-green-500 hover:border-transparent rounded"
                          >
                            {`${
                              (id.userRole === "HR" && data.statusByHR === 2) ||
                              (id.userRole === "Manager" &&
                                data.statusByManager === 2)
                                ? "Approved"
                                : "Approve"
                            }`}
                          </button>
                          <button
                            disabled={
                              (id.userRole === "HR" && data.statusByHR === 3) ||
                              (id.userRole === "Manager" &&
                                data.statusByManager === 3)
                                ? true
                                : false
                            }
                            onClick={() => handleRejectClick(data)}
                            className="bg-transparent disabled:cursor-no-drop disabled:opacity-25 hover:bg-red-500 text-red-700 font-semibold hover:text-white  px-2 border border-red-500 hover:border-transparent rounded"
                          >
                            {`${
                              (id.userRole === "HR" && data.statusByHR === 3) ||
                              (id.userRole === "Manager" &&
                                data.statusByManager === 3)
                                ? "Rejected"
                                : "Reject"
                            }`}
                          </button>
                        </>
                      ) : (
                        "Leave Canceled By Employee"
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="10" className="border px-4 py-2 text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* Modal */}
        {showModal && (
          <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-80 flex items-center justify-center">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
              <h2 className="text-lg font-semibold">Reason Details</h2>
              <p>{modalContent}</p>
              <button
                onClick={closeModal}
                className="mt-4 px-4 py-2 bg-black text-white rounded hover:bg-blue-600 focus:outline-none"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
      {totalCount > 10 && (
        <div className="mt-3 flex items-center justify-end">
          <nav aria-label="Page navigation example">
            <ul className="inline-flex -space-x-px text-sm">
              <li>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`flex items-center ${
                    currentPage === 1 ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Previous
                </button>
              </li>

              {Array.from({ length: totalRecords })?.map((_, index) => (
                <li key={index}>
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`flex items-center justify-center px-3 h-8 ${
                      currentPage === index + 1
                        ? "text-blue-600 border bg-blue-50"
                        : "text-gray-500 border bg-white"
                    } border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-800`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}

              <li>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalRecords}
                  className={`flex items-center justify-center ${
                    currentPage === totalRecords ? "cursor-no-drop" : ""
                  } px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow w-96">
            <h2 className="text-xl font-semibold mb-4">
              Enter Reason for Rejection
            </h2>
            <textarea
              className="w-full h-28 border rounded p-2 mb-4"
              value={reason}
              onChange={handleChange}
            />
            <div className="flex justify-end">
              <button
                className="mr-2 px-4 py-2 text-red-600 hover:bg-red-600 hover:text-white border rounded"
                onClick={handleClosePopup}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-green-400 text-white rounded"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default LeaveRequests;
