import React from "react";
import Chart from "react-apexcharts";

const Charts = () => {
  // Sample data for Total Employee chart
  const totalEmployeeData = {
    series: [50, 30, 20, 40], // Updated data
    options: {
      chart: {
        type: "pie",
      },
      labels: ["Dot Net", "ReactJS", "PHP/Laraval", "BDE"], // Updated labels
      colors: ["#512bd4", "#087ea4", "#EB4432", "#FF9900"], // Updated colors
      title: {
        text: "Total Employees",
        style: {
          fontSize: "20px",
        },
      },
    },
  };

  const workingHoursData = {
    series: [
      {
        name: "Working Hours",
        data: [8, 7.5, 8, 8, 7, 8, 6.5, 8, 8, 7.5, 8, 7], // Example data for each day of the month
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
      },
      title: {
        text: "Daily Working Hours",
        align: "left",
      },
      xaxis: {
        categories: [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
        ], // Example categories for days of the month
        title: {
          text: "Day of the Month",
        },
      },
      yaxis: {
        title: {
          text: "Hours Worked",
        },
        min: 0,
        max: 12,
      },
      stroke: {
        curve: "smooth",
      },
    },
  };

  // Sample data for Expenses chart
  const expensesData = {
    series: [
      {
        name: "Expenses",
        data: [10000, 20000, 15000, 30000, 25000, 35000, 40000], // Example data
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
      },
      title: {
        text: "Monthly Expenses",
        align: "left",
      },
      xaxis: {
        categories: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
      },
      stroke: {
        curve: "smooth",
      },
    },
  };

  // Sample data for Men and Women Ratio chart
  const menAndWomenRatio = {
    series: [60, 40], // Updated data
    options: {
      chart: {
        type: "pie",
      },
      labels: ["Men", "Women"], // Updated labels
      colors: ["#FF6384", "#36A2EB"], // Updated colors
      title: {
        text: "Employees Structure",
        style: {
          fontSize: "20px",
        },
      },
    },
  };

  return (
    <>
      <div className="w-full flex flex-col ">
        <div className="flex-grow p-4 border border-gray-300 border-dotted mt-10 rounded-lg">
          <Chart
            options={expensesData.options}
            series={expensesData.series}
            type="line"
            height={350}
          />
        </div>
      </div>
      <div className="w-full flex flex-col ">
        <div className="flex-grow p-4 border border-gray-300 border-dotted mt-10 rounded-lg">
          <Chart
            options={workingHoursData.options}
            series={workingHoursData.series}
            type="area"
            height={350}
          />
        </div>
      </div>

      <div className="flex flex-row gap-6">
        <div className=" border h-full pt-3 border-gray-200 border-dotted mt-10 rounded-lg w-full   justify-center">
          <Chart
            options={totalEmployeeData.options}
            series={totalEmployeeData.series}
            type="pie"
            height={350}
          />
        </div>
        <div className=" border h-full w-full pt-3 border-dotted border-gray-200 mt-10 rounded-lg   justify-center">
          <Chart
            options={menAndWomenRatio.options}
            series={menAndWomenRatio.series}
            type="pie"
            height={350}
          />
        </div>
      </div>
    </>
  );
};

export default Charts;
