import React from "react";

const Events = () => {
  const birthdays = [
    { name: "Marc Demo", role: "Experienced Developer", date: "February 20" },
  ];

  const events = [
    {
      title: "Business workshops",
      date: "2022-02-08 18:00:00 - 2022-02-08 22:30:00",
    },
    {
      title: "OpenWood Collection Online Reveal",
      date: "2022-02-12 05:00:00 - 2022-02-14 15:00:00",
      venue: "Kosovo",
    },
    {
      title: "OpenWood Collection Online Reveal",
      date: "2022-02-12 05:00:00 - 2022-02-14 15:00:00",
      venue: "Zimbabwe",
    },
    {
      title: "OpenWood Collection Online Reveal",
      date: "2022-02-12 05:00:00 - 2022-02-14 15:00:00",
      venue: "Zambia",
    },
  ];

  const announcements = [{ title: "GA0004", description: "Internal Meeting" }];

  return (
    <div className="flex  mt-10 gap-10">
      {/* Upcoming Birthdays */}
      <div className="w-full md:w-1/3 bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="bg-blue-500 text-white p-4">
          <h2 className="text-lg font-semibold">Upcoming Birthdays</h2>
        </div>
        <div className="p-4">
          {birthdays.map((birthday, index) => (
            <>
              <div key={index} className="mb-4">
                <h3 className="font-semibold text-gray-700">{birthday.name}</h3>
                <p className="text-gray-500">{birthday.role}</p>
                <p className="text-gray-500">{birthday.date}</p>
              </div>
              <hr />
            </>
          ))}
        </div>
      </div>

      {/* Upcoming Events */}
      <div className="w-full md:w-1/3 bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="bg-blue-500 text-white p-4">
          <h2 className="text-lg font-semibold">Upcoming Events</h2>
        </div>
        <div className="p-4">
          {events.map((event, index) => (
            <>
              <div key={index} className="mb-4">
                <h3 className="font-semibold text-gray-700">{event.title}</h3>
                <p className="text-gray-500">{event.date}</p>
                {event.venue && (
                  <p className="text-gray-500">Venue: {event.venue}</p>
                )}
              </div>
              <hr />
            </>
          ))}
        </div>
      </div>

      {/* Announcements */}
      <div className="w-full md:w-1/3 bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="bg-blue-500 text-white p-4">
          <h2 className="text-lg font-semibold">Announcements</h2>
        </div>
        <div className="p-4">
          {announcements.map((announcement, index) => (
            <>
              <div key={index} className="mb-4">
                <h3 className="font-semibold text-gray-700">
                  {announcement.title}
                </h3>
                <p className="text-gray-500">{announcement.description}</p>
              </div>
              <hr />
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Events;
