import React, { useEffect, useState } from "react";


import { ADMIN, EMPLOYEE, HR, MANAGER } from "../../config/constants";
import AdminDashboard from "./ADMIN";
import EmployeeDashboard from "./EMPLOYEE";
import HrDashboard from "./HR";
import ManagerDashboard from "./MANAGER";

const Dashboard = () => {
  const [currentUser, setCurrentUser] = useState([]);

  useEffect(() => {
    // Fetch data from localStorage
    const adminUserData = localStorage.getItem("user");
    if (adminUserData) {
      // Parse the JSON data if available
      const adminEvents = JSON.parse(adminUserData);
      setCurrentUser(adminEvents?.userRole);
    }
  }, []);
  return currentUser === ADMIN ? (
    <AdminDashboard />
  ) : currentUser === EMPLOYEE ? (
    <EmployeeDashboard />
  ) : currentUser === HR ? (
    <HrDashboard />
  ) : currentUser === MANAGER ? (
    <ManagerDashboard />
  ) : null;
};

export default Dashboard;
