import React, { useState } from "react";
import TooltipItem from "../../helper/TooltipItem";
import { FiUserCheck, FiUserX } from "react-icons/fi";
import ApiUtils from "../../api/ApiUtils";
import { useEffect } from "react";
import Toaster from "../../helper/Toaster";
import { ERROR_TOASTIFY_TYPE, SUCCESS_TOASTIFY_TYPE } from "../../helper/enum";
import { ONE, TEN } from "../../config/constants";
import { FaEye, FaEyeSlash, FaFemale, FaMale } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";

const Managers = () => {
  const [currentPage, setCurrentPage] = useState(ONE);
  const [totalRecords, setTotalRecords] = useState(ONE);
  const [totalCount, setTotalCount] = useState();
  const [showModal, setShowModal] = useState(false);
  const [manager, setManager] = useState([]);
  const [managerId, setManagerId] = useState();
  const [oldId, setOldId] = useState();
  const [userList, setUserlist] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [department, setDepartment] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  // Handler for changing the current page
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      address: "",
      gender: "Male",
      department: "",
      dob: "",
      joiningDate: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      mobile: Yup.number()
        .required("Mobile number is required")
        .test(
          "is-ten-digits",
          "Mobile number must be exactly 10 digits",
          (value) => /^\d{10}$/.test(value)
        ),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      address: Yup.string().required("Address is required"),
      department: Yup.string().required("Department is required"),
      dob: Yup.date().required("Date of birth is required"),
      joiningDate: Yup.date().required("Joining date is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      const apiData = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.mobile.toString(),
        password: values.password,
        address: values.address,
        gender: values.gender,
        role: "Manager",
        dob: values.dob,
        joiningDate: values.joiningDate,
        departmentId: Number(values.department),
        // assignedManagerId: "string",
        // assignedHrId: "string",
      };
      ApiUtils.addUser(apiData).then((res) => {
        if (res.data.isSuccess) {
          Toaster("Manager Added Succefull", SUCCESS_TOASTIFY_TYPE);
          getManagerList();
          handleClosePopup();
        } else {
          Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
        }
      });
    },
  });


  async function inactiveManager(data) {
    const apiData = {
      userId: data?.userId,
      isActive: data.isActive === true ? false : true,
    };

    ApiUtils.changeUserStatus(apiData)
      .then((res) => {
        if (res.data.isSuccess) {
          getManagerList();
        }
      })
      .catch((err) => {
        return err;
      });
  }

  const handleSwitchManager = (data) => {
    setShowModal(true);
    getManager();
    setOldId(data?.userId);
  };

  async function getManagerList() {
    await ApiUtils.getAllManager(`Page=${currentPage}&Pagesize=${TEN}`)
      .then((res) => {
        setUserlist(res?.data?.data?.userResponses);
        setTotalCount(res?.data?.data?.totalCount);
        setTotalRecords(
          res?.data?.data?.totalCount
            ? Math.ceil(res?.data?.data?.totalCount / TEN)
            : []
        );
      })
      .catch((err) => {
        return err;
      });
  }

  const handleUpdateManager = () => {
    const data = {
      managerId: oldId,
      newManagerId: managerId,
    };
    ApiUtils.updateUsersManager(data).then((res) => {
      if (res.data.isSuccess) {
        Toaster("Manager Updated Success", SUCCESS_TOASTIFY_TYPE);
        ApiUtils.changeUserStatus({ userId: oldId, isActive: false }).then(
          (res) => {
            if (res.data.isSuccess) {
              setShowModal(false);
              setOldId("");
              getManagerList();
            }
          }
        );
      } else {
        Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
      }
    });
  };

  async function getManager() {
    await ApiUtils.getManager()
      .then((res) => {
        setManager(res?.data?.data);
      })
      .catch((err) => {
        return err;
      });
  }

  async function getDepartments() {
    await ApiUtils.getDepartments()
      .then((res) => {
        setDepartment(res?.data);
      })
      .catch((err) => {
        return err;
      });
  }

  useEffect(() => {
    getManagerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const toggleModal = () => {
    setShowPopup((prevState) => !prevState);
    getDepartments();
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    formik.resetForm();
  };

  const handleChange = (e) => {
    setManagerId(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <React.Fragment>
      <div className="container flex items-center mt-3 justify-between">
        <h1 className="text-lg font-bold">MANAGERS</h1>
        <div>
          <button
            type="button"
            className="text-white bg-green-600 w-36 hover:bg-green-900 mb-1 h-8 rounded-full font-bold"
            onClick={toggleModal}
          >
            ADD MANAGER
          </button>
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs uppercase  bg-gray-700 text-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Mobile No.
              </th>
              <th scope="col" className="px-6 py-3">
                Role
              </th>
              <th scope="col" className="px-6 py-3">
                Department
              </th>
              <th scope="col" className="px-6 py-3">
                Users
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {userList?.length > 0 ? (
              userList?.map((item) => {
                return (
                  <tr
                    key={item.userId}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4 uppercase">
                      {`${item?.firstName} ${item?.lastName}`}
                    </td>
                    <td className="px-6 py-4">{item?.phone}</td>
                    <td className="px-6 py-4">{item?.role}</td>
                    <td className="px-6 py-4">{item?.department}</td>
                    <td className="px-6 py-4">{item?.totalUsers}</td>
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <div
                          className={`h-2.5 w-2.5 rounded-full ${
                            item.isActive === true
                              ? "bg-green-500 mr-2"
                              : "bg-red-500 mr-2"
                          } `}
                        ></div>
                        {item.isActive === true ? "Active" : "Inactive"}
                      </div>
                    </td>

                    <td className="px-6 py-4 flex gap-5">
                      <TooltipItem
                        className={`text-lg ${
                          item.isActive === true
                            ? "text-red-500"
                            : "text-green-500"
                        }`}
                        tooltipsText={
                          item.isActive === true ? "Inactive" : "Active"
                        }
                        onClick={
                          item.totalUsers === 0
                            ? () => inactiveManager(item)
                            : () => handleSwitchManager(item)
                        }
                      >
                        {item.isActive === true ? <FiUserX /> : <FiUserCheck />}
                      </TooltipItem>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="10" className="border px-4 py-2 text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalCount > 10 && (
        <div className="mt-3 flex items-center justify-end">
          <nav aria-label="Page navigation example">
            <ul className="inline-flex -space-x-px text-sm">
              <li>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`flex items-center ${
                    currentPage === 1 ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Previous
                </button>
              </li>

              {Array.from({ length: totalRecords })?.map((_, index) => (
                <li key={index}>
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`flex items-center justify-center px-3 h-8 ${
                      currentPage === index + 1
                        ? "text-blue-600 border bg-blue-50"
                        : "text-gray-500 border bg-white"
                    } border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-800`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}

              <li>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalRecords}
                  className={`flex items-center ${
                    currentPage === totalRecords ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white1`}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-80">
          <div className="bg-white w-full rounded-lg shadow-lg p-6 max-w-2xl  overflow-y-auto">
            <h2 className="text-2xl font-semibold mb-5 ">
              Switch User's Manager
            </h2>

            <form>
              <div className="mb-6">
                <label
                  htmlFor="manager"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Manager
                </label>
                <select
                  id="manager"
                  name="assignedManagerId"
                  onChange={handleChange}
                  className="bg-gray-50 borderborder-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected disabled value="">
                    Select a Manager
                  </option>
                  {manager?.map((manager) => (
                    <option key={manager.roleId} value={manager.userId}>
                      {manager.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex justify-end">
                <button
                  className="mr-2 px-4 py-2 text-red-600 hover:bg-red-600 hover:text-white border rounded"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpdateManager}
                  type="button"
                  className="px-4 py-2 bg-green-400 text-white rounded"
                >
                  Apply
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-80">
          <div className="bg-white w-full rounded-lg shadow-lg p-6 max-w-2xl h-[80vh] mt-12 overflow-y-auto">
            <h2 className="text-2xl font-semibold mb-5">Add Manager</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <div className="grid gap-6 md:grid-cols-2">
                <div className="mb-4">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.firstName}
                    </div>
                  ) : null}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.lastName}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="grid gap-6 md:grid-cols-2">
                <div className="mb-4">
                  <label
                    htmlFor="mobile"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mobile No
                  </label>
                  <input
                    type="number"
                    id="mobile"
                    name="mobile"
                    defaultValue={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  {formik.touched.mobile && formik.errors.mobile ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.mobile}
                    </div>
                  ) : null}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Address
                </label>
                <textarea
                  id="address"
                  name="address"
                  rows="3"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />{" "}
                {formik.touched.address && formik.errors.address ? (
                  <div className="text-red-500 text-sm">
                    {formik.errors.address}
                  </div>
                ) : null}
              </div>
              <div className="grid gap-6 md:grid-cols-2">
                <div className="mb-4">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Gender
                  </label>
                  <ul className="grid w-full gap-6 md:grid-cols-2">
                    <li>
                      <input
                        type="radio"
                        id="male"
                        value="Male"
                        name="gender"
                        className="hidden peer"
                        required
                        defaultChecked={true}
                        onChange={() => {
                          formik.setFieldValue("gender", "Male");
                        }}
                      />
                      <label
                        htmlFor="male"
                        className="inline-flex items-center justify-between w-full p-2 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                      >
                        <div className="block">
                          <div className="w-full text-lg font-semibold flex">
                            <FaMale className="mr-4 items-center mt-1" /> Male
                          </div>
                        </div>
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        id="female"
                        name="gender"
                        value="Female"
                        className="hidden peer"
                        onChange={() => {
                          formik.setFieldValue("gender", "Female");
                        }}
                        required
                      />
                      <label
                        htmlFor="female"
                        className="inline-flex items-center justify-between w-full p-2 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                      >
                        <div className="block">
                          <div className="w-full text-lg font-semibold flex">
                            <FaFemale className="mr-4 items-center mt-1" />{" "}
                            Female
                          </div>
                        </div>
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="department"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Department
                  </label>
                  <select
                    id="department"
                    name="department"
                    onChange={(e) =>
                      formik.setFieldValue("department", e.target.value)
                    }
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected disabled value="">
                      Select a Department
                    </option>
                    {department?.map((department) => (
                      <option
                        key={department.departmentId}
                        value={department.departmentId}
                      >
                        {department.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="grid gap-6 md:grid-cols-2">
                <div className="mb-4">
                  <label
                    htmlFor="dob"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    id="dob"
                    name="dob"
                    value={formik.values.dob}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  {formik.touched.dob && formik.errors.dob ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.dob}
                    </div>
                  ) : null}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="joiningDate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Joining Date
                  </label>
                  <input
                    type="date"
                    id="joiningDate"
                    name="joiningDate"
                    value={formik.values.joiningDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  {formik.touched.joiningDate && formik.errors.joiningDate ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.joiningDate}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 px-3 py-2 bg-gray-200 rounded-md border border-gray-300 hover:bg-gray-300 focus:outline-none"
                    id="toggleButton"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-red-500 text-sm">
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="mr-2 px-4 py-2 text-red-600 hover:bg-red-600 hover:text-white border rounded"
                  onClick={handleClosePopup}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-green-400 text-white rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Managers;
