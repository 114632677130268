import React from "react";
import Widgets from "./Widgets";
import Charts from "./Charts";
import RecentLeave from "./RecentLeave";
import Events from "./Events";

const ManagerDashboard = () => {
  return (
    <div className="dashboard mt-5">
      <Widgets />
      <Charts />
      <Events />
      <div className="mt-10">
        <h1 className="text-lg font-bold">Recent Leave</h1>
        <RecentLeave />
      </div>
    </div>
  );
};

export default ManagerDashboard;
