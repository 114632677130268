import React, { useEffect, useState } from "react";
import ApiUtils from "../../api/ApiUtils";
import { FaMale, FaFemale, FaEdit } from "react-icons/fa";
import { FiUserCheck, FiUserX } from "react-icons/fi";
import InputField from "../../components/CommonComponents/InputField";
import TooltipItem from "../../helper/TooltipItem";
import { AiOutlinePlus } from "react-icons/ai";
import Toaster from "../../helper/Toaster";
import { ERROR_TOASTIFY_TYPE, SUCCESS_TOASTIFY_TYPE } from "../../helper/enum";
import { useCallback } from "react";
import { debounce } from "../../helper/Debounce";
import { ONE, TEN } from "../../config/constants";

const UserList = () => {
  const [userList, setUserList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [department, setDepartment] = useState([]);
  const [roles, setRoles] = useState([]);
  const [manager, setManager] = useState([]);
  const [hrList, setHrList] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(ONE);
  const [searchValue, setSearchValue] = useState("");
  const sixteenYearsAgo = new Date();
  sixteenYearsAgo.setFullYear(sixteenYearsAgo.getFullYear() - 16);
  const [value, setValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    gender: "",
    role: "",
    dob: "",
    joiningDate: "",
    departmentId: "",
    assignedManagerId: "",
    assignedHrId: "",
    password: "",
  });

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    address: false,
    gender: false,
    role: false,
    dob: false,
    joiningDate: false,
    departmentId: false,
    assignedManagerId: false,
    assignedHrId: false,
    password: false,
  });





  async function getUserList() {
    await ApiUtils.getAllUser(
      `Page=${currentPage}&Pagesize=${TEN}&searchvalue=${searchValue}`
    )
      .then((res) => {
      
        setUserList(res?.data?.data?.userResponses);
        setTotalCount(res?.data?.data?.totalCount);
        setTotalRecords(
          res?.data?.data?.totalCount
            ? Math.ceil(res?.data?.data?.totalCount / TEN)
            : []
        );
      })
      .catch((err) => {
        return err;
      });
  }
  async function getDepartments() {
    await ApiUtils.getDepartments(`Page=1&PageSize=100`)
      .then((res) => {
        setDepartment(res?.data?.departmentResponses);
      })
      .catch((err) => {
        return err;
      });
  }
  async function getRoles() {
    await ApiUtils.getRoles()
      .then((res) => {
        setRoles(res?.data?.data);
      })
      .catch((err) => {
        return err;
      });
  }
  async function getManager() {
    await ApiUtils.getManager()
      .then((res) => {
        setManager(res?.data?.data);
      })
      .catch((err) => {
        return err;
      });
  }
  async function getHr() {
    await ApiUtils.getHr()
      .then((res) => {
        setHrList(res?.data?.data?.hrResponses);
      })
      .catch((err) => {
        return err;
      });
  }

  // handler for edit data
  const handleEdit = (item) => {
    setShowModal(true);
    setEditId(item.userId);
    setIsEdit(true);
    getDepartments();
    getRoles();
    getManager();
    setError({});
    getHr();
    setValue({
      firstName: item.firstName,
      lastName: item.lastName,
      email: item.email,
      phone: item.phone,
      address: item.address,
      role: item.role,
      dob: item.dob,
      gender: item.gender,
      joiningDate: item.doj,
      departmentId: item.departmentId,
      assignedManagerId: item.assignedManagerId,
      assignedHrId: item.assignedHrId,
    });
  };
  // handler for search
  const handleSearch = async (value) => {
    if ((userList.length > 0 && value.length >= 3) || value.length === 0) {
      setSearchValue(value);
      await ApiUtils.getAllUser(
        `Page=${currentPage}&PageSize=${TEN}&searchvalue=${value}`
      )
        .then((res) => {
          setUserList(res?.data?.data?.userResponses);
          setTotalCount(res?.data?.data?.totalCount);
          setTotalRecords(
            res?.data?.data?.totalCount
              ? Math.ceil(res?.data?.data?.totalCount / 10)
              : []
          );
        })
        .catch((err) => {
          return err;
        });
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(handleSearch), [userList]);

  // Function to toggle the modal visibility
  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
    setIsEdit(false);
    getDepartments();
    getRoles();
    getManager();
    getHr();
    setEditId("");
    setError({});
    setValue({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      gender: "",
      role: "",
      dob: "",
      joiningDate: "",
      departmentId: "",
      assignedManagerId: "",
      assignedHrId: "",
      password: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue((prevFormData) => ({ ...prevFormData, [name]: value }));
    setError((prevFormData) => ({ ...prevFormData, [name]: false }));

    if (name === "dob") {
      if (!value) {
        setError((prevFormData) => ({ ...prevFormData, dob: true }));
      } else {
        setError((prevFormData) => ({ ...prevFormData, dob: false }));

        const joiningDateInput = document.getElementById("joiningDate");
        joiningDateInput.min = value;
      }
    }

    if (name === "joiningDate" && value && value < value.dob) {
      // If joiningDate is less than dob
      setError((prevFormData) => ({ ...prevFormData, joiningDate: true })); // Set error to true
    } else {
      setError((prevFormData) => ({ ...prevFormData, joiningDate: false })); // Clear error
    }
  };

  const handleStatus = (e) => {
    const data = {
      userId: e?.userId,
      isActive: e.isActive === true ? false : true,
    };

    ApiUtils.changeUserStatus(data)
      .then((res) => {
        if (res.data.isSuccess) {
          getUserList();
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (!value.trim()) {
      setError((prevFormData) => ({ ...prevFormData, [name]: true }));
    } else {
      setError((prevFormData) => ({ ...prevFormData, [name]: false }));
    }
  };

  // Handler for changing the current page
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    getUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, currentPage]);

  const handleSubmit = () => {
    let formIsValid = true;
    const newErrorState = { ...error };

    // Validate first name
    if (value.firstName.trim() === "") {
      formIsValid = false;
      newErrorState.firstName = true;
    } else {
      newErrorState.firstName = false;
    }

    // Validate last name
    if (value.lastName.trim() === "") {
      formIsValid = false;
      newErrorState.lastName = true;
    } else {
      newErrorState.lastName = false;
    }

    // Validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(value.email)) {
      formIsValid = false;
      newErrorState.email = true;
    } else {
      newErrorState.email = false;
    }

    // Validate phone number
    const phonePattern = /^\d{10}$/;
    if (!phonePattern.test(value.phone)) {
      formIsValid = false;
      newErrorState.phone = true;
    } else {
      newErrorState.phone = false;
    }

    // Validate Password
    if (value?.password?.trim() === "") {
      formIsValid = false;
      newErrorState.password = true;
    } else {
      newErrorState.password = false;
    }
    // validate address
    if (value.address.trim() === "") {
      formIsValid = false;
      newErrorState.address = true;
    } else {
      newErrorState.address = false;
    }
    //validate role
    if (!value.role) {
      formIsValid = false;
      newErrorState.role = true;
    } else {
      newErrorState.role = false;
    }
    // validate HR
    if (!value.assignedHrId) {
      formIsValid = false;
      newErrorState.assignedHrId = true;
    } else {
      newErrorState.assignedHrId = false;
    }
    // validate Manager
    if (!value.assignedManagerId) {
      formIsValid = false;
      newErrorState.assignedManagerId = true;
    } else {
      newErrorState.assignedManagerId = false;
    }
    //validate Department
    if (!value.departmentId) {
      formIsValid = false;
      newErrorState.departmentId = true;
    } else {
      newErrorState.departmentId = false;
    }
    // validate Date of birth
    if (!value.dob) {
      formIsValid = false;
      newErrorState.dob = true;
    } else {
      newErrorState.dob = false;
    }
    // validate Joining Date
    if (!value.joiningDate) {
      formIsValid = false;
      newErrorState.joiningDate = true;
    } else {
      newErrorState.joiningDate = false;
    }

    // If the form is not valid, update the error state and return early
    if (!formIsValid) {
      setError(newErrorState);
      return;
    }
    const body = {
      userId: editId,
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email,
      phone: value.phone,
      password: value.password,
      address: value.address,
      gender: value.gender,
      role: value.role,
      dob: value.dob,
      joiningDate: value.joiningDate,
      departmentId: value.departmentId,
      assignedManagerId: value.assignedManagerId,
      assignedHrId: value.assignedHrId,
    };
    // add or edit api call function
    performOperation(isEdit, isEdit ? body : value)
      .then((res) => {
        if (res.data.isSuccess) {
          setShowModal(false);
          getUserList();
          setEditId("");
          setValue({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            address: "",
            gender: "",
            role: "",
            dob: "",
            joiningDate: "",
            departmentId: "",
            assignedManagerId: "",
            assignedHrId: "",
            password: "",
          });
          Toaster(res.data.message, SUCCESS_TOASTIFY_TYPE);
        } else {
          Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
        }
      })
      .catch((err) => {
        return err;
      });
  };
  // check is edit or add
  const performOperation = (checkMode, params) =>
    !checkMode ? ApiUtils.addUser(params) : ApiUtils.editUser(params);

  return (
    <React.Fragment>
      <div className="flex items-center justify-end gap-4 mt-3 pb-2">
        <div className="container flex items-center justify-between">
          <h1 className="text-lg font-bold">USER LIST</h1>
        </div>
        <div>
          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
        </div>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            type="text"
            id="table-search"
            className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search for items"
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
        <div>
          <button
            type="button"
            className="text-white flex items-center gap-1 bg-green-600 p-5 hover:bg-green-900 h-8 rounded-full font-bold"
            onClick={toggleModal}
          >
            <AiOutlinePlus />
            <span className="text-center">ADD </span>
          </button>
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs uppercase  bg-gray-700 text-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Mobile No.
              </th>
              <th scope="col" className="px-6 py-3">
                Role
              </th>
              <th scope="col" className="px-6 py-3">
                Department
              </th>
              <th scope="col" className="px-6 py-3">
                Manager
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {userList?.length > 0 ? (
              userList?.map((item) => {
                return (
                  <tr
                    key={item.userId}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4 uppercase hover:text-blue-400 hover:underline">
                      <a href={`/user-details/${item.userId}`}>
                        {`${item?.firstName} ${item?.lastName}`}
                      </a>
                    </td>
                    <td className="px-6 py-4">{item?.phone}</td>
                    <td className="px-6 py-4">{item?.role}</td>
                    <td className="px-6 py-4">{item?.department}</td>
                    <td className="px-6 py-4">{item?.assignManager || "-"}</td>
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <div
                          className={`h-2.5 w-2.5 rounded-full ${
                            item.isActive === true
                              ? "bg-green-500 mr-2"
                              : "bg-red-500 mr-2"
                          } `}
                        ></div>
                        {item.isActive === true ? "Active" : "Inactive"}
                      </div>
                    </td>

                    <td className="px-6 py-4 flex gap-5">
                      {item.role !== "Admin" && (
                        <TooltipItem
                          onClick={(e) => handleEdit(item)}
                          className="text-lg text-green-500"
                          tooltipsText="Edit"
                        >
                          <FaEdit />
                        </TooltipItem>
                      )}

                      {item.role !== "Manager" && item.role !== "Admin" && (
                        <TooltipItem
                          onClick={(e) => handleStatus(item)}
                          className={`text-lg ${
                            item.isActive === true
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                          tooltipsText={
                            item.isActive === true ? "Inactive" : "Active"
                          }
                        >
                          {item.isActive === true ? (
                            <FiUserX />
                          ) : (
                            <FiUserCheck />
                          )}
                        </TooltipItem>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="10" className="border px-4 py-2 text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalCount > 10 && (
        <div className="mt-3 flex items-center justify-end">
          <nav aria-label="Page navigation example">
            <ul className="inline-flex -space-x-px text-sm">
              <li>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`flex items-center ${
                    currentPage === 1 ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Previous
                </button>
              </li>

              {Array.from({ length: totalRecords })?.map((_, index) => (
                <li key={index}>
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`flex items-center justify-center px-3 h-8 ${
                      currentPage === index + 1
                        ? "text-blue-600 border bg-blue-50"
                        : "text-gray-500 border bg-white"
                    } border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-800`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}

              <li>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalRecords}
                  className={`flex items-center ${
                    currentPage === totalRecords ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white1`}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-80">
          <div className="bg-white w-full rounded-lg justify-center align-middle mt-12 shadow-lg h-[80vh] p-6 mt-5 max-w-xl overflow-y-auto">
            <h2 className="text-2xl font-semibold mb-5 ">
              {isEdit ? "Edit User" : "Add User"}
            </h2>

            <form>
              <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                  <InputField
                    type="text"
                    name="firstName"
                    label="First Name"
                    id="firstName"
                    placeholder="Enter First Name"
                    required
                    value={value.firstName}
                    error={error}
                    setError={setError}
                    setValue={setValue}
                  />
                </div>
                <div>
                  <InputField
                    type="text"
                    name="lastName"
                    label="Last Name"
                    id="lastName"
                    placeholder="Enter Last Name"
                    required
                    value={value.lastName}
                    error={error}
                    setError={setError}
                    setValue={setValue}
                  />
                </div>
                <div>
                  <InputField
                    label="Email"
                    id="email"
                    type="email"
                    placeholder="Enter Email Address"
                    required
                    name="email"
                    value={value.email}
                    setValue={setValue}
                    error={error}
                    setError={setError}
                    validator={(value) => {
                      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      return emailPattern.test(value);
                    }}
                    validationMessage="Please enter a valid email address."
                  />
                </div>

                <div>
                  <InputField
                    type="number"
                    name="phone"
                    label="Mobile No."
                    id="number"
                    placeholder="Enter Mobile Number"
                    required
                    value={value.phone}
                    error={error}
                    setError={setError}
                    setValue={setValue}
                    validator={(value) => /^\d{10}$/.test(value)}
                    validationMessage="Please enter a 10-digit phone number."
                  />
                </div>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="address"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Address
                </label>
                <textarea
                  id="address"
                  className={`bg-gray-50 border ${
                    error.address ? "border-red-500" : "border-gray-300"
                  }  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Enter Address..."
                  required
                  name="address"
                  defaultValue={value?.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {error.address && (
                  <p className="text-red-500 text-sm mt-1 dark:text-red-400">
                    Address is required.
                  </p>
                )}
              </div>

              <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                  <label
                    htmlFor="firstName"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Gender
                  </label>
                  <ul className="grid w-full gap-6 md:grid-cols-2">
                    <li>
                      <input
                        type="radio"
                        id="male"
                        value="Male"
                        name="gender"
                        className="hidden peer"
                        required
                        defaultChecked={value.gender ? value.gender : true}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="male"
                        className="inline-flex items-center justify-between w-full p-2 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                      >
                        <div className="block">
                          <div className="w-full text-lg font-semibold flex">
                            <FaMale className="mr-4 items-center mt-1" /> Male
                          </div>
                        </div>
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        id="female"
                        name="gender"
                        value="Female"
                        className="hidden peer"
                        required
                        defaultChecked={
                          value.gender === "Female" ? true : false
                        }
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="female"
                        className="inline-flex items-center justify-between w-full p-2 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                      >
                        <div className="block">
                          <div className="w-full text-lg font-semibold flex">
                            <FaFemale className="mr-4 items-center mt-1" />{" "}
                            Female
                          </div>
                        </div>
                      </label>
                    </li>
                  </ul>
                </div>
                <div>
                  <label
                    htmlFor="role"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Role
                  </label>
                  <select
                    id="role"
                    name="role"
                    required
                    onChange={handleChange}
                    value={value.role}
                    className={`bg-gray-50 border ${
                      error.role ? "border-red-500" : "border-gray-300"
                    }  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  >
                    <option selected disabled value="">
                      Select a role
                    </option>
                    {roles?.map((role) => (
                      <option key={role.id} value={role.name}>
                        {role.normalizedName}
                      </option>
                    ))}
                  </select>
                  {error.role && (
                    <p className="text-red-500 text-sm mt-1 dark:text-red-400">
                      Please Select Role
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="dob"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    DOB
                  </label>

                  <input
                    datepicker=""
                    datepicker-autohide=""
                    type="date"
                    id="dob"
                    className={`bg-gray-50 border ${
                      (error.dob || error.dob === "age") && !value.dob
                        ? "border-red-500"
                        : "border-gray-300"
                    }  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    placeholder="Select date"
                    name="dob"
                    onChange={handleChange}
                    value={value.dob}
                    max={sixteenYearsAgo.toISOString().split("T")[0]}
                  />

                  {error.dob && (
                    <p className="text-red-500 text-sm mt-1 dark:text-red-400">
                      Please select DOB
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="joiningDate"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Joining Date
                  </label>

                  <input
                    datepicker=""
                    datepicker-autohide=""
                    type="date"
                    id="joiningDate"
                    className={`bg-gray-50 border ${
                      error.joiningDate ? "border-red-500" : "border-gray-300"
                    }  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    placeholder="Select date"
                    name="joiningDate"
                    onChange={handleChange}
                    value={value.joiningDate}
                  />
                  {error.joiningDate && (
                    <p className="text-red-500 text-sm mt-1 dark:text-red-400">
                      Please Select Joining Date
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="department"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Department
                  </label>
                  <select
                    id="department"
                    name="departmentId"
                    onChange={handleChange}
                    value={value.departmentId}
                    className={`bg-gray-50 border ${
                      error.departmentId ? "border-red-500" : "border-gray-300"
                    }  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  >
                    <option selected disabled value="">
                      Select a Department
                    </option>
                    {department?.map((department) => (
                      <option
                        key={department.departmentId}
                        value={department.departmentId}
                      >
                        {department.name}
                      </option>
                    ))}
                  </select>
                  {error.departmentId && (
                    <p className="text-red-500 text-sm mt-1 dark:text-red-400">
                      Please Select Departmet
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="manager"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Manager
                  </label>
                  <select
                    id="manager"
                    name="assignedManagerId"
                    onChange={handleChange}
                    value={value.assignedManagerId}
                    className={`bg-gray-50 border ${
                      error.assignedManagerId
                        ? "border-red-500"
                        : "border-gray-300"
                    }  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  >
                    <option selected disabled value="">
                      Select a Manager
                    </option>
                    {manager?.map((manager) => (
                      <option key={manager.roleId} value={manager.userId}>
                        {manager.name}
                      </option>
                    ))}
                  </select>
                  {error.assignedManagerId && (
                    <p className="text-red-500 text-sm mt-1 dark:text-red-400">
                      Please Select Manager
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="hr"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    HR
                  </label>
                  <select
                    id="hr"
                    name="assignedHrId"
                    onChange={handleChange}
                    value={value.assignedHrId}
                    className={`bg-gray-50 border ${
                      error.assignedHrId ? "border-red-500" : "border-gray-300"
                    }  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  >
                    <option selected disabled value="">
                      Select a HR
                    </option>
                    {hrList?.map((hr) => (
                      <option key={hr.userId} value={hr.userId}>
                        {hr.name}
                      </option>
                    ))}
                  </select>
                  {error.assignedHrId && (
                    <p className="text-red-500 text-sm mt-1 dark:text-red-400">
                      Please Select HR
                    </p>
                  )}
                </div>
                {!isEdit && (
                  <div>
                    <InputField
                      label="Password"
                      name="password"
                      type="password"
                      placeholder="***************"
                      value={value.password}
                      setValue={setValue}
                      error={error}
                      setError={setError}
                      required
                      validator={(value) => value.length >= 6}
                      validationMessage="password must not be less then 6 digit"
                    />
                  </div>
                )}
              </div>
            </form>
            <div className="flex justify-end">
              <button
                className="mr-2 px-4 py-2 text-red-600 hover:bg-red-600 hover:text-white border rounded"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="px-4 py-2 bg-green-400 text-white rounded"
              >
                {isEdit ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default UserList;
