import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { SidebarProvider } from "../../helper/SidebarContext";

const Layout = (props) => {
  return (
    <React.Fragment>
      <SidebarProvider>
        <Header />
        <Sidebar />
      </SidebarProvider>
      <div
        className="p-3 mt-16 sm:ml-64 bg-white"
        style={{ minHeight: "calc(100vh - 120px)" }}
      >
        {props.children}
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default Layout;
