import api from "./index";
const ApiUtils = {
  //HR Expense apis
  updateExpense: async function (params) {
    try {
      const response = await api.post(`HrExpense/UpdateExpense`, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  itemsById: async function (params) {
    try {
      const response = await api.get(`HrExpense/GetItemsById?BillId=${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  addExpense: async function (params) {
    try {
      const response = await api.post(`HrExpense/AddExpense`, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteExpense: async function (params) {
    try {
      const response = await api.post(`HrExpense/DeleteExpense?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getAllExpense: async function (params) {
    try {
      const response = await api.get(`HrExpense/GetAllExpense?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  //policy apis
  updatePolicy: async function (params) {
    try {
      const response = await api.post(`Policy/UpdatePolicy`, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getPolicyById: async function (params) {
    try {
      const response = await api.get(`Policy/GetPolicyById?policyId=${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  addPolicy: async function (params) {
    try {
      const response = await api.post(`Policy/AddPolicy`, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteAllPolicy: async function (params) {
    try {
      const response = await api.post(`Policy/DeletePolicy?policyId=${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getAllPolicy: async function (params) {
    try {
      const response = await api.get(`Policy/GetAllPolicy?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },

  //in-out apis
  updateTimes: async function (params) {
    try {
      const response = await api.post(
        "TimeActivity/UpdateActivityRange",
        params
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getTimeList: async function (params) {
    try {
      const response = await api.get(
        `TimeActivity/GetUserActivityHours?${params}`,
        params
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },

  //interview apis
  addFeedback: async function (params) {
    try {
      const response = await api.post(
        "Candidate/UpdateInterviewStatus",
        params
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getCandidateForInterviewer: async function (params) {
    try {
      const response = await api.get(
        `Candidate/GetAllCandidatesForInterviewer?${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  addInterview: async function (params) {
    try {
      const response = await api.post("Candidate/AddInterview", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  candidateEmailValidate: async function (params) {
    try {
      const response = await api.get(`Candidate/IsEmailExists/${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getCandidateById: async function (params) {
    try {
      const response = await api.get(
        `Candidate/GetCandidateById?candidateId=${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  addCandidate: async function (params) {
    try {
      const response = await api.post("Candidate/AddUpdateCandidate", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getCandidateList: async function (params) {
    try {
      const response = await api.get(`Candidate/GetAllCandidates?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateUsersManager: async function (params) {
    try {
      const response = await api.post("Account/UpdateManager", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getUserByDepartment: async function (params) {
    try {
      const response = await api.get(
        `Account/GetUserByDepartmentIdAsync?departmentId=${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getUserById: async function (params) {
    try {
      const response = await api.get(`Account/User/${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getAllManager: async function (params) {
    try {
      const response = await api.get(`Account/GetAllUserManager?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteFestivalLeaves: async function (params) {
    try {
      const response = await api.post(
        `FestivalLeave/DeleteFestivalLeave?id=${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateFestivalLeaves: async function (params) {
    try {
      const response = await api.post("FestivalLeave/UpdateLeave", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  addFestivalLeaves: async function (params) {
    try {
      const response = await api.post("FestivalLeave/AddLeave", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getFestivalLeaves: async function (params) {
    try {
      const response = await api.get(`FestivalLeave/GetAllLeave${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  userCancelLeave: async function (params) {
    try {
      const response = await api.post("UserLeave/CancelLeave", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  userLeaveApproveOrReject: async function (params) {
    try {
      const response = await api.post("UserLeave/LeaveApproveOrReject", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getEarlyGoingListbyHrorManager: async function (params) {
    try {
      const response = await api.get(
        `EarlyComingLateGoing/GetAllGoingComingForHROrManager?${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  addGoingCoiming: async function (params) {
    try {
      const response = await api.post(
        "EarlyComingLateGoing/CreateOrUpdateGoingComing",
        params
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getEarlyGoingList: async function (params) {
    try {
      const response = await api.get(
        `EarlyComingLateGoing/GetAllGoingComingForUser?${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getUserActivityList: async function (params) {
    try {
      const response = await api.get(`TimeActivity/GetActivity?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getAllUserActivity: async function (params) {
    try {
      const response = await api.get(
        `EmployeeActivity/GetAllEmployeeActivity?${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateUserActivity: async function (params) {
    try {
      const response = await api.post(
        "EmployeeActivity/UpdateActivity",
        params
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  setUserActivity: async function (params) {
    try {
      const response = await api.post("TimeActivity/AddActivity", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getUserActivity: async function () {
    try {
      const response = await api.get("TimeActivity/GetLastActivity");
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getUserLeaveBalance: async function (params) {
    try {
      const response = await api.get(`UserLeave/RemainingLeaveCount/${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getLeavesByAdmin: async function (params) {
    try {
      const response = await api.get(
        `UserLeave/GetAllLeavesByAdminId?${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getLeavesByHr: async function (params) {
    try {
      const response = await api.get(
        `UserLeave/GetAllLeavesByManagerOrHRIds/${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  GetUserLeaves: async function (params) {
    try {
      const response = await api.get(
        `UserLeave/GetAllLeavesByUserId/${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateLeave: async function (params) {
    try {
      const response = await api.post("UserLeave/UpdateLeave", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  ApplyLeave: async function (params) {
    try {
      const response = await api.post("UserLeave/LeaveApply", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  leaveType: async function () {
    try {
      const response = await api.get("LeaveType");
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  editUser: async function (params) {
    try {
      const response = await api.put("Account/UpdateUser", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  addUser: async function (params) {
    try {
      const response = await api.post("Account/InsertUser", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getHr: async function () {
    try {
      const response = await api.get("Account/GetUserByHRRoleId");
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getManager: async function () {
    try {
      const response = await api.get("Account/GetManagerUserByManagerRoleId");
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getRoles: async function () {
    try {
      const response = await api.get("Account/GetAllRole");
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  addDepartments: async function (params) {
    try {
      const response = await api.post("Department", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getDepartments: async function (params) {
    try {
      const response = await api.get(`Department/GetAll?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  deleteDepartment: async function (params) {
    try {
      const response = await api.delete(`Department/${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateDepartment: async function (params) {
    try {
      const response = await api.put(`Department`, params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  resetPassword: async function (params) {
    try {
      const response = await api.post("Password/ResetPassword", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  changeUserStatus: async function (params) {
    try {
      const response = await api.post("Account/ActiveDeactiveUser", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getUserByManagerId: async function (params) {
    try {
      const response = await api.get(
        `Account/GetUserByManagerId?userId=${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getAllUser: async function (params) {
    try {
      const response = await api.get(`Account/GetAllUser?${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  forgotPassword: async function (params) {
    try {
      const response = await api.post("Password/ForgotPassword", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  authLogin: async function (params) {
    try {
      const response = await api.post("Login", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getLeaveCountOfYear: async function (params) {
    try {
      const response = await api.get(
        `UserLeave/GetAllLeaveTakenByUser/${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getEducationDetails: async function (params) {
    try {
      const response = await api.get(`Education/GetEducationById/${params}`);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  createEducationDetails: async function (params) {
    try {
      const response = await api.post("Education", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateEducationDetails: async function (params) {
    try {
      const response = await api.put("Education", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getPersonalDetails: async function (params) {
    try {
      const response = await api.get(
        `PersonalDetail/GetPersonalByUserId/${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  createPersonalDetails: async function (params) {
    try {
      const response = await api.post("PersonalDetail", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updatePersonalDetails: async function (params) {
    try {
      const response = await api.put("PersonalDetail", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  getBankDetails: async function (params) {
    try {
      const response = await api.get(
        `BankDetail/GetBankDetialsByUserId/${params}`
      );
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  createBankDetails: async function (params) {
    try {
      const response = await api.post("BankDetail", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
  updateBankDetails: async function (params) {
    try {
      const response = await api.put("BankDetail", params);
      return response;
    } catch (error) {
      throw error.response;
    }
  },
};

export default ApiUtils;
