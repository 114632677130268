import React, { useEffect, useState } from "react";
import ApiUtils from "../../api/ApiUtils";
import DatePicker from "react-datepicker";
import { useCallback } from "react";
import { debounce } from "../../helper/Debounce";
import moment from "moment";
import TooltipItem from "../../helper/TooltipItem";
import { FaHourglassStart, FaPencilAlt } from "react-icons/fa";
import Toaster from "../../helper/Toaster";
import { BsArrowDownLeft, BsArrowUpRight } from "react-icons/bs";
import { ERROR_TOASTIFY_TYPE } from "../../helper/enum";
import { ONE, TEN } from "../../config/constants";

const ApprovalUpdateTiming = () => {
  const [list, setList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [showModal, setShowModal] = useState(false);
  const [timingData, setTimingData] = useState();

  const [totalRecords, setTotalRecords] = useState(ONE);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [rejectMessage, setRejectMessage] = useState("");

  const handleReject = () => {
    // Placeholder logic for handling rejection
    // Additional logic here such as sending a request to the server
    // or updating state accordingly
    setShowModal(false); // Close the modal after rejection
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const formatedStartDate =
    startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "";
  const formatedEndDate =
    endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "";

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  async function getAllList() {
    ApiUtils.getUserActivityList(
      `PageSize=${TEN}&Page=${currentPage}&SearchValue=${searchValue}&fromDate=${formatedStartDate}&toDate=${formatedEndDate}`
    ).then((res) => {
      setList(
        res?.data?.data?.listResponse ? res?.data?.data?.listResponse : []
      );
      setTotalCount(
        res?.data?.data?.totalCount ? res?.data?.data?.totalCount : ""
      );
      setTotalRecords(
        res?.data?.data?.totalCount
          ? Math.ceil(res?.data?.data?.totalCount / TEN)
          : []
      );
    });
  }

  useEffect(() => {
    getAllList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, startDate, endDate]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handleSearch = async (value) => {
    setSearchValue(value);
    if ((list.length > 0 && value.length >= 3) || value.length === 0) {
      ApiUtils.getAllUserActivity(
        `PageSize=${TEN}&Page=${currentPage}&SearchValue=${value}&fromDate=${formatedStartDate}&toDate=${formatedEndDate}`
      ).then((res) => {
        setList(
          res?.data?.data?.listResponse ? res?.data?.data?.listResponse : []
        );
        setTotalCount(
          res?.data?.data?.totalCount ? res?.data?.data?.totalCount : ""
        );
        setTotalRecords(
          res?.data?.data?.totalCount
            ? Math.ceil(res?.data?.data?.totalCount / 10)
            : []
        );
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(handleSearch), [list]);

  const handleView = (userId, Date) => {
    setShowModal(true);
    ApiUtils.getTimeList(`userId=${userId}&date=${Date}`).then((res) => {
      if (res.data.isSuccess) {
        setTimingData(res?.data?.data?.listResponse);
      } else {
        Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
      }
    });
  };

  // get details of user from localStorage
  const currentUser = JSON.parse(localStorage.getItem("user"));

  return (
    <React.Fragment>
      <div className="flex items-center justify-end gap-4 mt-3 pb-2">
        <div className="container flex items-center justify-between">
          <h1 className="text-lg font-bold">Approval Update Timing</h1>
        </div>
        <div>
          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
        </div>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            type="text"
            id="table-search"
            className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search for items"
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
        <div className="flex">
          <div className="flex">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              showIcon
              todayButton="TODAY"
              selected={startDate}
              onChange={handleStartDateChange}
              placeholderText="Select start date"
              className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="flex">
            <label className="ml-2 mr-2">To:</label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              showIcon
              todayButton="TODAY"
              selected={endDate}
              onChange={handleEndDateChange}
              placeholderText="Select end date"
              className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs uppercase  bg-gray-700 text-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3">
                Employee Name
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Total Hours
              </th>

              <th scope="col" className="px-6 py-3">
                Action
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.length > 0 ? (
              Array.isArray(list) &&
              list
                ?.filter((el) => el.userId !== currentUser?.userId)
                ?.map((data, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td className="px-6 py-4">{data.userName}</td>
                      <td className="px-6 py-4">
                        {moment(data.date).format("DD-MM-YYYY")}
                      </td>
                      <td className="px-6 py-4">{data.totalTime}</td>

                      <td className="px-6 py-4">
                        {" "}
                        <TooltipItem
                          onClick={() => handleView(data.userId, data.date)}
                          className="text-md text-green-500"
                          tooltipsText="Edit"
                        >
                          <FaPencilAlt />
                        </TooltipItem>
                      </td>
                      <td className="px-6 py-4 flex items-center">
                        <FaHourglassStart className="text-yellow-500 mr-2" />
                        Pending
                      </td>
                    </tr>
                  );
                })
            ) : (
              <tr>
                <td colSpan="10" className="border px-4 py-2 text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalCount > 10 && (
          <div className="mt-3 flex items-center justify-end">
            <nav aria-label="Page navigation example">
              <ul className="inline-flex -space-x-px text-sm">
                <li>
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`flex items-center ${
                      currentPage === 1 ? "cursor-no-drop" : ""
                    } justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                  >
                    Previous
                  </button>
                </li>

                {Array.from({ length: totalRecords })?.map((_, index) => (
                  <li key={index}>
                    <button
                      onClick={() => handlePageChange(index + 1)}
                      className={`flex items-center justify-center px-3 h-8 ${
                        currentPage === index + 1
                          ? "text-blue-600 border bg-blue-50"
                          : "text-gray-500 border bg-white"
                      } border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-800`}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}

                <li>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalRecords}
                    className={`flex items-center justify-center ${
                      currentPage === totalRecords ? "cursor-no-drop" : ""
                    } px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}

        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-80">
            <div
              className="bg-white rounded-lg shadow-lg p-8 max-w-4xl overflow-y-auto"
              style={{ width: "40%" }}
            >
              <h2 className="text-2xl font-semibold mb-5">Employee Timings</h2>

              <form>
                <div className="flex flex-col gap-4">
                  <div className="flex items-center gap-8 ">
                    {/* Column for Current Timing */}
                    <div className="flex flex-col">
                      <label className="mb-1">Current Timing</label>
                      {timingData?.map((data, index) => (
                        <div key={index} className="flex gap-4 items-center">
                          <BsArrowDownLeft className="text-xl text-green-500" />
                          <input
                            type="time"
                            id={`inTime${index}`}
                            name="inTime"
                            disabled
                            className="p-2.5 w-full text-sm cursor-no-drop text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            defaultValue={moment(data.inTime)
                              .tz("Asia/Kolkata")
                              .format("HH:mm")}
                          />
                          {data.outTime && (
                            <>
                              <BsArrowUpRight className="text-xl text-red-500" />
                              <input
                                type="time"
                                id={`outTime${index}`}
                                name="outTime"
                                disabled
                                className="p-2.5 w-full text-sm cursor-no-drop text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                defaultValue={moment(data.outTime)
                                  .tz("Asia/Kolkata")
                                  .format("HH:mm")}
                              />
                            </>
                          )}
                        </div>
                      ))}
                    </div>

                    {/* Perpendicular Line */}

                    <div className="border-l border-solid border-gray-400 h-full mx-4">
                      {" "}
                    </div>

                    {/* Column for Updated Timing */}
                    <div className="flex flex-col">
                      <label className="mb-1">Updated Timing</label>
                      {timingData?.map((data, index) => (
                        <div key={index} className="flex gap-4 items-center">
                          <BsArrowDownLeft className="text-xl text-green-500" />
                          <input
                            type="time"
                            id={`updatedInTime${index}`}
                            name="updatedInTime"
                            disabled
                            className="p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            defaultValue={moment(data.inTime)
                              .tz("Asia/Kolkata")
                              .format("HH:mm")}
                          />
                          {data.outTime && (
                            <>
                              <BsArrowUpRight className="text-xl text-red-500" />
                              <input
                                type="time"
                                id={`updatedOutTime${index}`}
                                name="updatedOutTime"
                                disabled
                                className="p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                defaultValue={moment(data.outTime)
                                  .tz("Asia/Kolkata")
                                  .format("HH:mm")}
                              />
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mt-5">
                  {/* Message after the columns */}
                  <p className="text-sm text-gray-500">
                    <strong>Reason for updating time request:</strong>{" "}
                    {"Stuck in Traffic"}
                  </p>

                  <div className="flex justify-between mt-4">
                    <button
                      className="px-4 py-2 text-white bg-green-600 hover:text-white border rounded"
                      // onClick={() => handleApprove()}
                    >
                      Approve
                    </button>
                    <button
                      className={`px-4 py-2 text-white bg-red-600 border rounded ${
                        !rejectMessage && "opacity-50 cursor-not-allowed"
                      }`}
                      onClick={() => handleReject()}
                      disabled={!rejectMessage}
                    >
                      Reject
                    </button>
                  </div>
                  <textarea
                    className="border border-gray-300 mt-3 p-2 w-full"
                    rows="3"
                    placeholder="Enter rejection reason..."
                    value={rejectMessage}
                    onChange={(e) => setRejectMessage(e.target.value)}
                  ></textarea>
                </div>
              </form>
              <div className="flex justify-end mt-5">
                <button
                  className="mr-2 px-4 py-2 text-red-600 hover:bg-red-600 hover:text-white border rounded"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ApprovalUpdateTiming;
