import React, { useState } from "react";

const Payslip = () => {
  const [payslips, setPayslips] = useState([
    {
      id: 1,
      month: "January",
      year: 2024,
      downloadLink: "/download/payslip1.pdf",
    },
    {
      id: 2,
      month: "February",
      year: 2024,
      downloadLink: "/download/payslip2.pdf",
    },
    {
      id: 3,
      month: "March",
      year: 2024,
      downloadLink: "/download/payslip3.pdf",
    },
    {
      id: 4,
      month: "April",
      year: 2024,
      downloadLink: "/download/payslip4.pdf",
    },
    {
      id: 5,
      month: "May",
      year: 2024,
      downloadLink: "/download/payslip5.pdf",
    },
    {
      id: 6,
      month: "June",
      year: 2024,
      downloadLink: "/download/payslip6.pdf",
    },
  ]);

  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  console.log("🚀 ~ Payslip ~ setSelectedMonth:", setSelectedMonth);
  console.log("🚀 ~ Payslip ~ setSelectedYear:", setSelectedYear);

  console.log("🚀 ~ Payslip ~ setPayslips:", setPayslips);
  // Function to filter payslips by year and month
  const filteredPayslips = payslips.filter((payslip) => {
    if (selectedYear && selectedMonth) {
      return payslip.year === selectedYear && payslip.month === selectedMonth;
    } else if (selectedYear) {
      return payslip.year === selectedYear;
    } else if (selectedMonth) {
      return payslip.month === selectedMonth;
    } else {
      return true;
    }
  });
  return (
    <React.Fragment>
      <div className="flex items-center justify-end pb-1">
        <div className="container flex items-center justify-between">
          <h1 className="text-lg font-bold">PAYSLIP</h1>
        </div>

        <div className="flex items-center w-1/2 justify-end">
          <div className="mr-2">Filter by Year:</div>
          <div>
            <select
              id="yearFilter"
              value={selectedYear}
              className="border rounded px-2 py-1 text-black"
            >
              <option value="">All</option>
              {Array.from(new Set(payslips?.map((payslip) => payslip.year)))?.map(
                (year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                )
              )}
            </select>
          </div>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs uppercase  bg-gray-700 text-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3">
                Month
              </th>
              <th scope="col" className="px-6 py-3">
                Year
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredPayslips?.map((payslip) => (
              <tr
                key={payslip.id}
                className="bg-white border-b hover:bg-gray-50"
              >
                <td className="px-6 py-4">{payslip.month}</td>
                <td className="px-6 py-4">{payslip.year}</td>
                <td className="px-6 py-4">
                  <a
                    href={payslip.downloadLink}
                    download
                    className="text-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"
                  >
                    Download
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default Payslip;
