import React from "react";
import Chart from "react-apexcharts";

const Charts = () => {
  // Sample data for Total Employee chart
  const workingHoursData = {
    series: [
      {
        name: "Working Hours",
        data: [8, 7.5, 8, 8, 7, 8, 6.5, 8, 8, 7.5, 8, 7], // Example data for each day of the month
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
      },
      title: {
        text: "Daily Working Hours",
        align: "left",
      },
      xaxis: {
        categories: [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
        ], // Example categories for days of the month
        title: {
          text: "Day of the Month",
        },
      },
      yaxis: {
        title: {
          text: "Hours Worked",
        },
        min: 0,
        max: 12,
      },
      stroke: {
        curve: "smooth",
      },
    },
  };

  return (
    <div className="w-full flex flex-col ">
      <div className="flex-grow p-4 border border-gray-300 border-dotted mt-10 rounded-lg">
        <Chart
          options={workingHoursData.options}
          series={workingHoursData.series}
          type="area"
          height={350}
        />
      </div>
    </div>
  );
};

export default Charts;
