import React from "react";
import CountUp from "react-countup";
import { IoMdCash } from "react-icons/io";
import { Link } from "react-router-dom";
import {
  FaBuilding,
  FaUmbrellaBeach,
  FaUser,
  FaUserCheck,
  FaUserTimes,
} from "react-icons/fa";
import { AiOutlineApartment } from "react-icons/ai";

const Widgets = () => {


  const widgetsData = [
    {
      id: 1,
      name: "NUMBER OF EMPLOYEES",
      //   amount: cardData?.orderCount,
      amount: 50,

      icon: <FaUser />,
      iconColor: "bg-green-100",
      redirectTo: "#",
    },
    {
      id: 2,
      name: "NUMBER OF DEPARTMENT",
      //   amount: cardData?.delivered,
      amount: 5,

      icon: <AiOutlineApartment />,
      iconColor: "bg-blue-100",
      redirectTo: "#",
    },
    {
      id: 3,
      name: "TODAY EMPLOYEE ON LEAVE",
      amount: 40,
      icon: <FaUser />,
      iconColor: "bg-red-100",
      redirectTo: "#",
    },
    {
      id: 4,
      name: "NUMBER OF HOLIDAY THIS MONTH",
      amount: 9,
      icon: <FaUmbrellaBeach />,
      iconColor: "bg-teal-100",
      redirectTo: "#",
    },
    {
      id: 5,
      name: "TOTAL HIRED",
      amount: 109,
      icon: <FaUserCheck />,
      iconColor: "bg-green-100",

      redirectTo: "#",
    },
    {
      id: 6,
      name: "RESIGNED EMPLOYEES",
      amount: 2,
      icon: <FaUserTimes />,
      iconColor: "bg-red-100",
      redirectTo: "#",
    },
    {
      id: 7,
      name: "NUMBER OF ASSETS",
      amount: 109,
      icon: <FaBuilding />,
      iconColor: "bg-green-100",
      redirectTo: "#",
    },
    {
      id: 7,
      name: "ASSIGNED ASSETS",
      amount: 20,
      icon: <IoMdCash />,
      iconColor: "bg-teal-100",
      redirectTo: "#",
    },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
      {widgetsData.map((item, key) => (
        <div key={key}>
          <Link to={item.redirectTo} className="text-decoration-none">
            <div className={`p-4 rounded-lg shadow-md ${item.iconColor}`}>
              <div className="relative">
                <div className="flex gap-x-[30%] items-center mb-2">
                  <h2 className="text-sm text-gray-600 uppercase font-medium">
                    {item.name}
                  </h2>
                  <div className="absolute top-0 right-0">{item.icon}</div>
                </div>
                <div className="text-xl font-semibold text-gray-600">
                  {item.decimal ? "$" : ""}
                  <CountUp
                    start={0}
                    end={item.amount || 0}
                    separator=","
                    decimals={item.decimal ? 2 : undefined}
                  />
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Widgets;
